import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { useNavigate } from 'react-router';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  try {
    const decoded = jwtDecode(serviceToken);

    const tokenIssuedAt = decoded.iat;
    const currentTime = Date.now() / 1000;

    const isValid = (currentTime - tokenIssuedAt) < 86400;

    return isValid;
  } catch (error) {
    console.error('Invalid Token:', error);
    return false;
  }
};


const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const user = JSON.parse(localStorage.getItem('user'));
          dispatch({
            type: LOGIN,
            payload: { user },
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        refreshToken();
        console.log('token error')
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`/auth/login`, {
        email,
        password,
      }, {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      });
      console.log("login response: ", response)
      localStorage.setItem("user", JSON.stringify(response.data));
      const serviceToken = response?.data?.token;
      const userId = response?.data?.id;
      const role = response?.data?.role;
      const name = response?.data?.name;
      setSession(serviceToken);
      localStorage.setItem("userId", userId);
      localStorage.setItem('email', email);
      localStorage.setItem('role', role);
      localStorage.setItem('name', name);

      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
        },
      });
      navigate('/analytics');
      // window.location.reload();
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const refreshToken = async () => {
    const storedEmail = localStorage.getItem("email");
    const storedRefreshToken = localStorage.getItem("refreshToken");

    const refreshResult = await axios.post(`/refresh`, {
      email: storedEmail,
      refreshToken: storedRefreshToken,
    });

    console.log(refreshResult);

    if (refreshResult.data.success) {
      localStorage.setItem("serviceToken", refreshResult.data.token);
      window.location.reload();
    } else {
      logout();
    }
  };

  const register = async (email, password, name) => {
    const response = await axios.post('/auth/signup', {
      email,
      password,
      name,
      role: 'user'
    });
    console.log("user created: ", response)
    navigate('/login')
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
    localStorage.clear();
  };

  const resetPassword = async () => { };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
